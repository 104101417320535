$c_primary: #cdff00;
$c_secondary: #252525;
$c_secondary_light: #373737;
$c_primary_hover: #fff;
$c_form_border: rgba(0, 0, 0, 0.2);
$c_white: #fff;
$c_black: #000;
$c_red: #ff0000;
$c_red_hover: #c20303;
$c_text_grey: #999;
$shadow: rgba(0, 0, 0, 1) 0px 0px 17px -6px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$transition: all 0.3s ease-in-out 0s;

// Common
body {
  background: $c_secondary;
}
.anchor_link {
  color: $c_primary;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $c_primary_hover;
  }
}

// Button
.MuiButton-root {
  &.btn {
    height: 50px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_primary {
    color: $c_black;
    background-color: $c_primary;
    border: 1px solid $c_primary;

    &:hover {
      background-color: $c_primary_hover;
      border: 1px solid $c_primary;
    }
  }

  &.btn_danger {
    color: $c_white;
    background-color: $c_red;

    &:hover {
      background-color: $c_red_hover;
    }
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}

// Forms
.custom_label[class*="MuiTypography"] {
  font-size: 14px;
  font-weight: 600;
  color: $c_white;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  > .MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      > fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }

  :is(input, select, input.form-control) {
    color: $c_white;
    font-weight: 500;
    height: 50px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 5px !important;
    border: 1px solid #eef2f6;
    box-shadow: none !important;
    background-color: #373737;
    &:focus {
      border-color: $c_primary;
    }

    &::placeholder {
      color: #9aa4b2;
      opacity: 1;
    }
  }

  :is(input, select) {
    padding: 5px 15px;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
        color: #9aa4b2;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 0 0 -3px !important;
  gap: 4px;

  .MuiCheckbox-root {
    padding: 0;
    // color: $c_form_border;
    color: $c_white;
    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $c_primary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    color: $c_white;
  }
}

.form_btn {
  margin-top: 30px;
}

// Auth
.auth_module {
  min-height: 100vh;
  background: $c_secondary;
  background-attachment: fixed;

  .auth_logo {
    margin-bottom: 30px;

    .logo_cntnr {
      height: 60px;
      margin: 0;

      img {
        height: 100%;
      }
    }
  }

  .auth_cntnr {
    max-width: 480px;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
  }

  .auth_box {
    max-width: 100%;
    border-radius: 30px;
    background-color: $c_secondary_light;
    padding: 40px 40px;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    h5 {
      font-size: 20px;
      font-weight: 800;
      color: $c_white;
    }
  }

  .anchor_link,
  .anchor_link p {
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
  }

  .opt_fields {
    max-width: 300px;
    margin-inline: auto;
    display: flex;
  }
}

// Sidebar
.sidebar-main-div {
  .sidebar_drawer {
    .MuiDrawer-paper {
      background-color: $c_secondary_light;
    }
  }

  .sidebar-logo {
    padding: 20px 20px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_secondary_light;
    z-index: 9;
    text-align: center;

    figure {
      width: 100%;
      height: 70px;
      margin: 0;

      img {
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
      }
    }
  }

  .sidebr-lst {
    padding: 0 0 20px;

    .lst-itm {
      padding: 5px 20px;
      min-height: 55px;
      gap: 10px;
      transition: $transition;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .MuiListItemIcon-root {
        height: 35px;
        min-width: 35px;
        color: $c_secondary_light;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $c_white;

        svg {
          height: 20px;
          width: 20px;
        }
      }

      .lstitm-txt {
        margin: 0;
        color: rgba(255, 255, 255, 0.9);
        display: inline-flex;
        transition: $transition;

        span {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.3px;
          line-height: 1.2;
          // transition: $transition;
        }
      }

      &.Mui-selected {
        border-color: $c_primary;
        background-color: $c_primary;
        .MuiListItemIcon-root {
          background: $c_secondary_light;
          color: $c_white;
        }
        .lstitm-txt {
          color: $c_black;

          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

// Topbar
.top_bar {
  .topbar {
    box-shadow: $shadow;
    background-color: $c_white;

    .MuiToolbar-root {
      min-height: 70px;
      padding: 10px 20px;
      box-sizing: border-box;
      background: $c_secondary_light;
      button {
        color: $c_white;
        background-color: rgba(255, 255, 255, 0.1);
        span {
          color: $c_white;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
          color: white;
          span {
            color: white;
          }
        }
      }
    }
  }

  .topbar_user_btn {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 8px;
    border-radius: 5px;
    background-color: #f9f9f9;

    img {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #555;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-left: 3px;
    }
  }

  .notification_btn,
  .chat_btn {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background-color: #f9f9f9;

    &:hover {
      color: $c_primary;
    }
  }

  .chat_btn {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      width: 7px;
      height: 7px;
      background-color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      opacity: 0;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.userDropdown_menu {
  .userDropdown_menu_list {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    min-width: 120px;

    button {
      padding: 0;
      pointer-events: none;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.notiDropdown_menu {
  .MuiMenu-paper {
    max-width: 450px;
    overflow-y: auto;
  }

  ul.MuiList-padding {
    padding: 0;
  }

  .noti_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 15px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_white;
    z-index: 2;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_black;
    }

    .btn {
      height: 30px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .noti_list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;
      padding: 10px 15px;
      gap: 10px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        background-color: rgba(32, 78, 51, 0.1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;
        color: $c_black;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;

        span {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: $c_text_grey;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        color: #555;
      }
    }
  }
}

// Main
.dashboard_main {
  .sub-layout {
    padding: calc(70px + 25px) 20px 25px;
    max-width: 100% !important;
  }

  .cards {
    padding: 20px 20px;
    // box-shadow: $shadow2;
    border-radius: 10px;
    color: $c_black;
    overflow: initial;
    background: $c_secondary_light;

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      &_right {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }
}

.main_loyout {
  .dashboard {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    h1 {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0;
      color: $c_white;
    }
  }
}

.dashGrid {
  .dashGrid_item {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: left;
    gap: 20px;
    border-bottom: 2px solid $c_secondary_light;
    cursor: pointer;
    transition: $transition;
    background-color: $c_secondary_light;
    min-height: 75px;

    &:hover {
      transform: translateY(-5px);
    }

    .svg_icn {
      height: 45px;
      width: 45px;
      min-width: 45px;
      padding: 10px;
      color: $c_secondary;
      background: $c_primary;
      border-radius: 5px;
      box-sizing: border-box;
    }

    h3 {
      font-size: 14px;
      color: $c_white;
      font-weight: 600;
      letter-spacing: 0.3px;
      margin: 0;
      line-height: 1;
    }

    h4 {
      font-size: 25px;
      color: $c_white;
      padding-top: 10px;
      word-break: break-all;
      margin: 0;
      line-height: 1;
      font-family: "Montserrat", sans-serif !important;
    }
  }
}

.dashGraph {
  .dashGraph_item {
    text-align: left;

    h2 {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0 0 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: $c_white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        color: $c_white;
      }
    }
  }
}

.search_bar {
  width: 260px;
  position: relative;
  // input {
  //   background-color: $c_white !important;
  //   color: $c_black !important;
  //   &:focus {
  //     border-color: $c_secondary !important;
  //   }
  // }

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }

  input {
    padding-left: 40px;
  }
}

.table_container {
  margin-top: 20px;

  table {
    width: 100%;

    tr {
      :is(th, td) {
        font-size: 14px;
        font-weight: 500;
        color: $c_white;
        line-height: 1.2;
        letter-spacing: 0;
        border: 1px solid rgba(244, 242, 242, 0.1);
        padding: 10px 15px;
        white-space: nowrap;
        box-sizing: border-box;
      }

      th {
        font-weight: 700;
        background-color: $c_secondary;

        &:first-child {
          &:has(input[type="checkbox"]) {
            width: 60px;
            min-width: 60px;
          }
        }

        &:nth-child(2):contains("S.No") {
          width: 70px;
          min-width: 70px;
        }

        &:first-child,
        &:nth-child(2) {
          &:contains("S.No") {
            width: 70px;
            min-width: 70px;
          }
        }
      }
    }

    .user_img {
      margin: 0;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .table_actions {
      display: inline-flex;
      align-items: center;
      gap: 5px;

      button {
        height: 35px;
        width: 35px;
        min-width: 35px;
        padding: 0;
        border-radius: 50%;
        background-color: #f5f5f5;
        color: $c_secondary;
        transition: $transition;

        svg {
          font-size: 18px;
        }

        &:hover {
          color: $c_black;
          background-color: $c_primary;
          border: 2px solid $c_white;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
    }
  }

  .MuiFormControl-root {
    select {
      height: 35px;
      padding: 2px 20px 2px 10px !important;

      & + svg {
        font-size: 20px;
        right: 0;
      }
    }
  }
}

.upload_image {
  label {
    display: inline-flex;
  }

  &_btn[class*="btn"] {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_black;
      background-color: $c_primary;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }

  &_preview {
    display: inline-flex;
    position: relative;
    align-items: center;
    flex-direction: column;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_red;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }
}

.view_box {
  .profile_img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin: 0;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &_list {
    .MuiBox-root {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      h2 {
        color: $c_primary;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      h5 {
        color: $c_primary;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      p {
        color: $c_white;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .detail_title {
    font-size: 16px;
    font-weight: 700;
  }
}

.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 20px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      border: 1px solid #ffffff6b;
      border-radius: 5px;
      padding: 10px;
      gap: 5px;

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 40px;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 600;
        color: $c_text_grey;
        text-transform: capitalize;
        border-radius: 5px;
        line-height: normal;
        transition: $transition;

        &:hover {
          color: #d5c8c8;
          background-color: rgb(78 78 32 / 30%);
        }

        &.Mui-selected {
          color: $c_secondary;
          background-color: $c_primary;
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }
}

.faq_box {
  max-width: 70%;
  background-color: $c_secondary_light;
  box-shadow: $shadow;
  padding: 20px;
  border-radius: 5px;
  position: relative;

  .btn {
    position: absolute;
    border: 1px solid $c_primary !important;
    left: calc(100% + 10px);
    top: 0;

    &:hover {
      background-color: $c_secondary_light !important;
      border: 1px solid $c_primary !important;
      color: $c_white !important;
    }
  }
}
.btn.Mui-disabled {
  color: white !important;
  border: none !important;
  background: $c_secondary !important;
  // box-shadow: $shadow !important;
}
.revert_dialog {
  .MuiDialog-paper {
    max-width: 400px;
    width: 100%;

    .MuiDialogContent-root {
      padding: 30px;
    }
  }

  .dialog_title {
    font-size: 18px;
    font-weight: 700;
    color: $c_black;
    margin-bottom: 10px;
  }

  .form_btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.react-tel-input {
  .country-list {
    padding: 0;

    .search-box {
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      line-height: normal;
      padding: 5px 10px;
      width: 100%;
      margin: 0;
      border: 1px solid $c_form_border;
    }

    li {
      &.search {
        padding: 10px 10px 5px 10px;
      }

      &.country {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 10px 8px 40px;
      }

      .flag {
        left: 10px;
        top: 4px;
        margin: 0;
      }
    }
  }
}

.chat_box {
  max-width: 1000px;
  margin: 0 auto;

  .chat_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_white;
      padding: 10px 15px;
      background-color: $c_primary;
      min-height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .chat_list {
      flex-grow: 1;
      padding: 0;
      margin: 0;
      list-style: none;
      max-height: 460px;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.1);

      li {
        display: flex;
        align-items: flex-start;
        padding: 10px 15px;
        gap: 10px;
        cursor: pointer;
        transition: $transition;
        position: relative;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: rgba(32, 78, 51, 0.1);
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        figure {
          height: 35px;
          width: 35px;
          min-width: 35px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .flex {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 15px;
          margin: 0 0 2px;

          h3 {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            color: $c_black;
            white-space: normal;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            color: $c_text_grey;
            white-space: nowrap;
          }
        }

        p {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: #555;
          white-space: normal;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 30px;
        }

        .MuiBadge-root {
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          right: 15px;
          bottom: 10px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $c_white;
          background-color: $c_primary;
          border-radius: 50%;
        }
      }
    }
  }

  .chat_right {
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    box-sizing: border-box;

    &_head {
      display: flex;
      align-items: center;
      min-height: 50px;
      gap: 5px;
      padding: 5px 15px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      figure {
        height: 35px;
        width: 35px;
        min-width: 35px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .chat_body {
    padding: 15px;
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
    box-sizing: border-box;
  }

  .chat_msg {
    display: flex;
    flex-direction: column;
    max-width: 70%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &_head {
      display: flex;
      align-items: center;
      gap: 10px;

      figure {
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        color: $c_text_grey;
        display: inline-flex;
      }
    }

    &_info {
      padding: 15px;
      background-color: #f5f5f5;
      border-radius: 0 15px 15px 15px;
      margin-top: 5px;

      p {
        font-size: 14px;
        font-weight: 500;
        color: $c_black;
        margin: 0;
        line-height: 1.5;
      }
    }

    &.sent {
      margin-left: auto;
      text-align: right;

      .chat_msg_head {
        justify-content: flex-end;
      }

      .chat_msg_info {
        border-radius: 15px 0 15px 15px;
      }
    }
  }

  .chat_foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    input {
      border-radius: 0;
      border: none;
      padding: 5px 15px;
      height: 60px;
      width: 100%;
    }

    button {
      height: 60px;
      width: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $c_primary;

      svg {
        font-size: 20px;
      }
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.inner_tabs {
  button {
    min-height: 40px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 600;
    color: $c_text_grey;
    text-transform: capitalize;
    border-radius: 5px;
    line-height: normal;
    transition: $transition;

    &:hover {
      color: #d5c8c8;
      background-color: rgb(78 78 32 / 30%);
    }
  }
}

.react-tel-input .form-control:focus {
  color: $c_black !important;
}
.select_div {
  border: 1px solid white;
  color: white !important;
  svg {
    color: white;
  }
}
.doc_img {
  height: 220px;
  width: 320px;
  margin: 0;
  img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

.box {
  width: calc(100% / 2 - var(--gap_x));
  &.w_100 {
    width: calc(100% - var(--gap_x));
  }
  h3 {
    color: $c_white;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    &:has(+ h3) {
      margin-bottom: 10px;
    }
  }
}
.box_group {
  h3 {
    margin-bottom: 20px;
  }
  .box {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

// table css
.box {
  background-color: #373737;
  border-radius: 20px;
  // padding: 20px;
  ul {
    &.list_data {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $c_white;
        font-size: 18px;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        span {
          &.c_secondary {
            color: $c_secondary;
          }
          &.c_secondary,
          b {
            font-weight: 700;
          }
        }
        hr {
          width: 100%;
          border: none;
          height: 1px;
          display: block;
          background-color: #eef2f6;
        }
      }
    }
    &.list_document {
      display: flex;
      flex-wrap: wrap;
      gap: 15px 40px;
      li {
        display: inline-flex;
        align-items: center;
        color: $c_white;
        gap: 10px;
        img {
          position: relative;
          top: -1px;
        }
      }
    }
    &.gap_p {
      --gap_x: 20px;
      --gap_y: 25px;
      margin-bottom: 25px;
      li {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        gap: 5px;
        strong {
          color: $c_white;
          font-weight: 400;
        }
        span {
          font-size: 14px;
          color: #9aa4b2;
        }
      }
    }
  }
  p {
    &.price_label {
      font-size: 18px;
      color: $c_white;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 25px;
      strong {
        font-size: 20px;
      }
    }
  }
  &.address_box {
    padding: 0;
    background-color: transparent;
    h3 {
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      color: #9aa4b2;
    }
  }
  .map {
    display: flex;
    margin-top: 30px;
    border-radius: 20px;
    overflow: hidden;
    iframe {
      width: 100%;
      border: none;
      height: 300px;
      filter: invert(0.9);
    }
  }
  table {
    &:not(.simple_table) {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      :is(th, td) {
        color: $c_white;
        font-size: 16px;
        border: 1px solid #eef2f6;
        text-align: left;
        padding: 15px 20px;
      }
      th {
        font-weight: 400;
      }
      td {
        font-weight: 600;
      }
    }
    &.simple_table {
      width: 100%;
      border-collapse: collapse;
      :is(th, td) {
        color: $c_white;
        border-bottom: 1px solid #252525;
        text-align: left;
        padding: 15px 20px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      th {
        font-size: 24px;
        font-weight: 600;
      }
      td {
        font-size: 14px;
      }
      thead {
        th {
          padding-top: 0;
        }
      }
      tbody {
        tr {
          &:last-child {
            td {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .progress {
    height: 16px;
    background-color: #252525;
    border-radius: 20px;
    width: 100%;
    display: flex;
    overflow: hidden;
    margin-top: -10px;
    &_status {
      background-color: $c_secondary;
      border-radius: 20px;
      height: 100%;
      transition: $transition;
    }
    &_selector {
      display: flex;
      gap: 30px;
      margin-top: 30px;
      .single {
        display: inline-flex;
        gap: 10px;
        > span {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: inline-flex;
          background-color: $c_secondary;
        }
        p {
          display: inline-flex;
          flex-direction: column;
          gap: 10px;
          color: $c_white;
          line-height: 1;
          strong {
            font-size: 20px;
            font-weight: 300;
          }
        }
        &:nth-child(2) {
          > span {
            background-color: #252525;
          }
        }
      }
    }
  }
}
.select_div {
  height: 50px !important;
}
.disabled_text .Mui-disabled {
  -webkit-text-fill-color: white !important;
  opacity: 1 !important;
}
.text_field textarea {
  color: #fff;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px !important;
  border: 1px solid #eef2f6;
  box-shadow: none !important;
  background-color: #373737;
  padding: 10px;
}
.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 400;
}
.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  figure {
    width: 60px;
    height: 60px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
.flexdiv {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;
  button {
    border: 1px solid white;
    color: white;
    &:hover {
      background-color: #cdff00;
      color: #252525;
      border: 1px solid #cdff00;
    }
  }
}
.docs_div {
  display: flex;
  gap: 10px;
  figure {
    margin: 0;
    height: 200px;
    width: 300px;
    position: relative;
    svg {
      position: absolute;
      top: 48%;
      right: 48%;
      cursor: pointer;
      color: $c_primary;
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      border: 1px solid white;
      object-fit: cover;
    }
  }
}

//pagination
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "regular";
    font-size: 18px;
  }
  p {
    font-size: 14px;
    font-family: "regular";
    span {
      font-family: "bold";
      color: white;
    }
  }
  .pages {
    display: flex;
    .prevbtn {
      background: no-repeat;
      border: none;
    }
    svg {
      font-size: 12px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      color: white;
    }
    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #a5a5a5;
      .actv {
        background: #cdff00;
        color: black;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: white;
        cursor: pointer;
        border: none;
      }
    }
  }
}
.cross_btn {
  font-size: 15px !important;
  cursor: pointer !important;
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
  max-height: initial;

  right: 10px;
  svg {
    width: 20px !important;
    height: 20px !important;
    color: white;
  }
}
.value_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: white;
  list-style-type: disc;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid white;
}

.gallery_modal {
  .modalBox_body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    width: 100%;
    background: transparent;
    position: relative;
    z-index: 1;
    min-height: 100%;

    > .MuiBox-root {
      padding: 25px;
      margin: 0 auto;
      max-width: 570px;
      width: 100%;
      border-radius: 30px;
      background: #252525;
      box-shadow: 0px 8px 16px -6px rgba(45, 115, 227, 0.08),
        0px 6px 8px -6px rgba(45, 115, 227, 0.12);
    }
    .close_btn {
      position: absolute;
      right: 50px;
      top: 20px;
      min-width: 1px;
      width: auto;
      padding: 0;
      color: $c_white;
      transition: $transition;
      &:hover {
        color: $c_primary;
      }
    }
    > .MuiBox-root {
      max-width: 80vw;
    }
    .gallery_swiper {
      .swiper-wrapper {
        margin-bottom: 0;
      }
      .swiper_action {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        gap: 15px;

        :is(.swiper-button-next, .swiper-button-prev) {
          position: initial;
          margin: 0;
          transform: none;
          height: auto;
          width: auto;

          svg {
            fill: rgba(154, 164, 178, 1);
            width: 22px;
            height: 22px;
            transition: $transition;
          }

          &::after {
            display: none;
          }

          &:hover {
            svg {
              fill: $c_secondary;
            }
          }
        }

        .swiper-pagination {
          position: initial;
          transform: none !important;

          .swiper-pagination-bullet {
            margin: 0 3px;
            background-color: #373737;
            opacity: 1;

            &.swiper-pagination-bullet-active {
              background-color: $c_secondary;
            }
          }
        }
      }
      .swiper-slide {
        figure {
          height: calc(80vh - (40px + 50px + 42px));
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}

.other_gallery {
  width: 33.5%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  figure {
    flex-grow: 1;
    position: relative;
    margin: 0;
    border-radius: 10px !important;
    > img {
      // position: absolute;
      // inset: 0;
      border-radius: 10px !important;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    video {
      border-radius: 10px !important;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .more {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-flex;
      align-items: center;
      min-height: 36px;
      gap: 15px;
      color: $c_white;
      font-size: 14px;
      font-weight: 400;
      padding: 2px 20px;
      border-radius: 30px;
      border: 2px solid $c_white;
      background: rgba(37, 37, 37, 0.8);
      white-space: nowrap;
      transition: $transition;
      cursor: pointer;

      img {
        height: 20px;
      }

      &:hover {
        background-color: rgba(37, 37, 37, 1);
        border-color: $c_secondary;
      }
    }
  }
}
.blog_img {
  margin: 0;

  img {
    width: 350px;
    height: 250px;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
    border: 1px solid white;
  }
}

.upload_image_bnr,
.upload_image_preview2 {
  border: 1px solid #fff;
  width: 40%;
  border-radius: 3px;
  height: 200px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  margin: 10px 0;
  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  img {
    height: 100%;
  }
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
.upload_image_dv {
  position: relative !important;
  &:hover {
    background-color: transparent !important;
  }
  .add_icn_btn {
    position: absolute;
    top: 50%;
    right: 50%;
    cursor: pointer;
    background-color: #cdff00;
    color: #000000c4;
    border-radius: 50%;
  }
}
.nw_bx {
  max-width: 100% !important;
}

.upload_document {
  .inner {
    width: 100%;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #373737;
    border: 1px solid white;
    // background-color: $c_secondary;
    border-radius: 4px;
    position: relative;
    > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 300;
      color: $c_white;
      gap: 20px;
      img {
        height: 50px;
      }
    }
  }
  &.v2 {
    .inner {
      border: 1px solid #eef2f6;
      // svg {
      //   font-size: 40px;
      // }
    }
  }
  figure {
    height: 200px;
    width: 300px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  input[type="file"] {
    position: absolute;
    inset: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    text-indent: -1000px;
  }
}
.cross_icn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #cdff00;
}
.imgs_sec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.w {
  &_50 {
    width: 30%;
  }
  &_100 {
    width: 100%;
  }
}
.our_team_mn {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  .our_team_sec {
    width: 23%;
    border: 1px solid white;
    border-radius: 6px;
    position: relative;
    svg {
      position: absolute;
      color: #cdff00;
      right: 45%;
      top: 45%;
      cursor: pointer;
    }

    figure {
      height: 150px;
      width: 100%;
      margin: 0;
      border-bottom: #fff;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 6px 6px 0 0;
      }
    }
    h4 {
      margin: 0;
      color: #fff;
      text-align: center;
    }
    p {
      color: gainsboro;
      text-align: center;
      margin: 0;
    }
  }
}
.cpy_icn {
  svg {
    font-size: 16px !important;
  }
  &.Mui-disabled {
    color: rgba(244, 243, 243, 0.2) !important;
  }
}

.docs_modal {
  .modalBox_body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    width: 100%;
    background: transparent;
    position: relative;
    z-index: 1;
    min-height: 100%;

    > .MuiBox-root {
      padding: 25px;
      margin: 0 auto;
      max-width: 570px;
      width: 100%;
      border-radius: 30px;
      background: #252525;
      box-shadow: 0px 8px 16px -6px rgba(45, 115, 227, 0.08),
        0px 6px 8px -6px rgba(45, 115, 227, 0.12);
    }
    .close_btn {
      position: absolute;
      right: 50px;
      top: 20px;
      min-width: 1px;
      width: auto;
      padding: 0;
      color: $c_white;
      transition: $transition;
      &:hover {
        color: $c_primary;
      }
    }
    > .MuiBox-root {
      max-width: 80vw;
      height: 500px;
    }
  }
}

.table_actions_revert{
  button{
    height: 35px !important;
    width: 35px !important;;
    min-width: 35px !important;;
    padding: 0 !important;;
    border-radius: 50% !important;;
    background-color: #f5f5f5 !important;;
    color: #252525 !important;; 
    transition: all 0.3s ease-in-out 0s !important;
  }
}

h2.reviewdiv{
  font-size: 17px;
  font-weight: 600;
  margin: 20px 0;
  color: rgb(205, 255, 0);
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.div_parent {
  ul{
    padding-left: 0;
    li{
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #80808017;
      padding-left: 0;
      figure{
        margin: 0;
        width: 46px;
        height: 46px;
        border-radius: 100%;
        background: black;
        img{
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .user_detail{
        margin-left: 10px;
        width: 75%;
        h3{
          margin: 0;
          font-size: 18px;
          text-transform: capitalize;
        }
        p{
          margin: 0;
          padding-top: 7px;
          color: #808080a1;
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }
}